@import "../theme.module";
.mainContainer{
  // background: url('https://cdn-network.sgp1.cdn.digitaloceanspaces.com/hoolva-client-images/Hoolva-background.jpg') no-repeat;
  // background-size: cover;
  width: 100%;
  height: 100%;
  .logo{
    text-align: center;
    .spinner {
      margin-top: 10px;
    }
  }
  div:first{
    height: calc(100vh - 48px);
  }
  .logo img{
    height: 100px;
  }
  .networkStatusOffline {
    position: absolute !important;
    bottom: 0 !important;
    width: 100%;
    display: block;
    text-align: center;
  }
  .networkStatusOnline {
    position: absolute !important;
    bottom: 0 !important;
    width: 100%;
    display: none;
    text-align: center !important;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
    transition-duration: 5s;
  }

  textarea:focus, input:focus{
    outline: none;
  }
  // .carousel{
  //   width: 100%;
  //   position: absolute !important;
  //   top: 0px;
  //   left: 0px;
  //   height: 100vh;
  //   display: flex  !important;
  // }
  .flexs {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flexBottom {
    align-self: flex-end;
    height: 48px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: #2b343a;
    z-index: 0;

    .center {
      min-width: 240px;
      display: flex;
      justify-content: space-between;

      .ButtomIcon {
        min-width: 48px;
        // background-color: red;;
        color: #a7a9ab;
        text-align: center;
        padding: 4px;
        cursor: pointer;
        // border: 1px solid #404041;
      }

      .ButtomIcon:hover {
        color: #fff;
      }

      .active {
        color: #f7931d;
      }
    }
  }
}

