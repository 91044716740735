@import "colorDefinitions";
@import "functions";
:root {
  --litelogo: #404041;
  --litebackground: #fff;
  --litebutton1BGColor: #f7931d;
  --litebutton1BorderColor:#f7931d;
  --litebutton1TextColor: #ffffff;
  --litebutton1HoverBGColor: #f7931d;
  --litebutton2BGColor: #fff;
  --litebutton2BorderColor: #f7931d;
  --litebutton2TextColor: #404041;
  --litebutton2HoverTextColor: #f7931d;
  --litebutton3BGColor: #fff;
  --litebutton3BorderColor: #fff;
  --litebutton3TextColor: #404041;
  --litebutton3HoverBorderColor: #f7931d;
  --litecheckboxBorder: #404041;
  --litecheckboxTicColor: #404041;
  --literadioBorder: #404041;
  --literadio_selectedColor: #404041;
  --litetextColor1: #404041;
  --litetextColor2: #fff;
  --litetextColor3: #a7a9ab;
  --litetextColorLink: #00adef;
  --litetextboxBGColor: #fff;
  --litetextboxBorderColor: #404041;
  --litetextboxActiveColor: #f7931d;
  --litetextboxTextColor: #404041;
  --litetextboxPlaceholderColor: #58585b;
  --litechatBGColor: #fff;
  --litevideoRoomPrimaryColor: #000;
  --litevideoRoomSecondaryColor: #202020;
  --litevideoRoomTernaryColor: #2b343a;
  --liteiconColor: #a7a9ab;
  --liteiconHoverColor: #fff;
  --liteiconHoverBGColor: #3a454c;
  --litewarningColor: #ee4923;
  --litedropdownBorderColor: #404041;
  --litemodalBorderColor: #2b343a;
}
$lite: (
  danger: color(hoolvaDarkRed),
  success: color(hoolvaLightGreen),
  logo: var(--litelogo),
  background: var(--litebackground),
  button1BGColor: var(--litebutton1BGColor),
  button1BorderColor: var(--litebutton1BorderColor),
  button1TextColor: var(--litebutton1TextColor),
  button1HoverBGColor: var(--litebutton1HoverBGColor),
  button2BGColor: var(--litebutton2BGColor),
  button2BorderColor: var(--litebutton2BorderColor),
  button2TextColor: var(--litebutton2TextColor),
  button2HoverTextColor: var(--litebutton2HoverTextColor),
  button3BGColor: var(--litebutton3BGColor),
  button3BorderColor: var(--litebutton3BorderColor),
  button3TextColor: var(--litebutton3TextColor),
  button3HoverBorderColor: var(--litebutton3HoverBorderColor),
  checkboxBorder: var(--litecheckboxBorder),
  checkboxTicColor: var(--litecheckboxTicColor),
  radioBorder: var(--literadioBorder),
  radio_selectedColor: var(--literadio_selectedColor),
  textColor1: var(--litetextColor1),
  textColor2: var(--litetextColor2),
  textColor3: var(--litetextColor3),
  textColorLink: var(--litetextColorLink),
  textboxBGColor: var(--litetextboxBGColor),
  textboxBorderColor: var(--litetextboxBorderColor),
  textboxActiveColor: var(--litetextboxActiveColor),
  textboxTextColor: var(--litetextboxTextColor),
  textboxPlaceholderColor: var(--litetextboxPlaceholderColor),
  chatBGColor: var(--litechatBGColor),
  videoRoomPrimaryColor: var(--litevideoRoomPrimaryColor),
  videoRoomSecondaryColor: var(--litevideoRoomSecondaryColor),
  videoRoomTernaryColor: var(--litevideoRoomTernaryColor),
  iconColor: var(--liteiconColor),
  iconHoverColor: var(--liteiconHoverColor),
  iconHoverBGColor: var(--liteiconHoverBGColor),
  warningColor: var(--litewarningColor),
  dropdownBorderColor: var(--litedropdownBorderColor),
  modalBorderColor: var(--litemodalBorderColor),
);
