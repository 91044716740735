.hoolva {
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 99999999999;
    top: 0px;
    left: 0px;
    .header{
        padding: 10px;
        border-bottom: 1px solid #404041;
    }
    .cancelButtonDiv {
        padding: 5px;
        text-align: center;
        margin-bottom: 5px;
        float: right;
        padding-right: 16px;
        padding-bottom: 10px;
        button {
            padding: 16px 24px;
            margin-right: 8px;
            line-height: 0px;
            border-radius: 3px;
        }
    }
    .desabled{
        background-color: gray;
        color: white;
        border: 1px solid gray;
        cursor:not-allowed
    }
    .center {
        align-items: center;
        justify-content: center;
    }

    .flex {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .main {
        height: calc(100vh - 48px);
        
        .content {
            padding: 10px 10px;
            min-width: 280px;
            font-size: 20px;
            text-align: center;
            display: flex;
            .connect{
                width: 100%;
                padding: 20px 10px;
                font-size: 15px;
            }
            svg{
                margin-right: 16px;
                font-size: 28px;
                animation-name: backgroundColorPalette;
                animation-duration: 5s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
            }
            @keyframes backgroundColorPalette {
                0% {
                  color: red;
                }
                30% {
                    color: green;
                }
                60% {
                    color: blue;
                }
                100% {
                    color: red;
                }
              }
        }
    }
}
.transperency{
    opacity: 0.6;
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 9999999999;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
