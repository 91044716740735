.TopBar {
  position: fixed;
  display: flex;
  top: 0px;
  width: 100%;
  padding: 5px 0px;
  transition: 1s;
  z-index: 888888888;
  .HoolvaLogo {
    height: 24px;
    cursor: pointer;
  }
  .button {
    width: 40px;
    margin: 0px 4px !important;
    padding: 4px;
    height: 40px;
    border-radius: 2px;
    svg {
      font-size: 22px;
    }
  }
  .devStatus {
    position: fixed;
    z-index: 999999999;
    top: 8px;
    left: 80px;
  }

  .recording {
    padding: 9.5px 14px;
    display: inline-block;
    font-size: 17px;
    margin-top: 1px;
    margin-right: 4px;
    text-align: left;
    width: 70px;

    border-radius: 2px;
    color: #afa9ab;
    cursor: pointer;
    svg {
      margin-right: 10px;
      color: #afa9ab;
    }
  }
  .Stop {
    svg {
      margin-right: 10px;
      color: #ee4923;
    }
  }
  .recording1 {
    padding: 9.5px 14px;
    display: inline-block;
    font-size: 17px;
    margin-right: 4px;
    text-align: left;
    height: 19px;
    margin-top: 0px;
    width: 70px;
    border-radius: 2px;
    color: #afa9ab;
  }
  .Exit {
    transform-origin: center;
    transform: rotate(270deg);
  }
  .screenShareList {
    display: inline-block;
    width: 280px;
    color: #fff;
    margin-top: 4px;
    font-size: 16px;
    margin-left: 32px;
  }
  .ScreenShare {
    display: block;
    min-width: 200px;
    cursor: pointer;
    position: relative;
    padding: 8px;
    padding-right: 20px;
    max-width: 300px;
    .dropArrow {
      position: absolute;
      right: -8px;
      font-size: 20px;
      margin-top: -8px;
      padding: 8px;
    }
  }
  .rightOpen {
    padding-right: 10px !important;
  }
  .time {
    width: auto !important;
  }
  .button:hover {
    background-color: #272727;
  }
  .button:hover svg {
    color: #fff;
  }
  .start {
    width: 420px;
    height: 40px;
    padding-left: 30px;
    text-align: start;
    position: relative;
    display: flex;
    align-items: center;
    .InfoSpan {
      display: inline-block;
      padding: 4px;
      .Info {
        width: 26px;
        height: 26px;
        color: #afa9ab;
      }
    }
    .talking {
      margin-top: 11px;
      position: absolute;
      width: max-content;
      height: 18px;
      color: white;
      display: flex;
      font-size: 16px;
      margin-left: 72px !important;
      padding: 10px;
    }
    .talkingName {
      display: inline-block;
      padding-right: 4px;
    }
  }
  .mid {
    width: 100%;
    text-align: center;
  }
  .end {
    width: 380px;
    padding-right: 40px;
    text-align: end;
    display: flex;
    justify-content: flex-end;
  }
  @media only screen and (max-width: 1024px) {
    .recording1 {
      margin-top: 2px;
    }
    .talkingName {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .end {
      padding-right: 4px;
      width: 380px !important;
      // text-align: start;
      button {
        width: 40px;
        height: 40px;
        margin: 2px !important;
      }
    }
    .LeftPosition .end {
      text-align: start !important;
    }
    .start {
      padding-left: 4px;
    }
  }
  @media only screen and (max-width: 800px) {
    .talkingName {
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media only screen and (max-width: 600px) {
    .end {
      padding-right: 4px;
      width: 400px !important;
      text-align: start;
    }
    .talking{
      display: none;
    }
    .start {
      padding-left: 4px;
    }
    .LeftPosition {
      .end {
        text-align: start;
      }
    }
  }
  .dropdown {
    position: relative;
    display: inline-block;
  }
  .dropDownContent {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 100%;
    cursor: pointer;
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropDownContent span {
    color: black;
    padding: 12px 8px;
    text-decoration: none;
    display: block;
  }

  /* Change color of dropdown links on hover */
  .dropDownContent span:hover {
    background-color: #ddd;
  }

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropDownContent {
    display: block;
  }

  /* Change the background color of the dropdown button when the dropdown content is shown */
  //   .dropdown:hover .dropbtn {background-color: #3e8e41;}
}

.Position {
  width: calc(100% - 302px) !important;
}
.LeftChatPosition {
  width: calc(100% - 302px) !important;
  margin-left: 0px !important;
}
.LeftPosition {
  width: calc(100% - 0px) !important;
  margin-left: 0px !important;
  margin-right: 0px;
}
