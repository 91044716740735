body {
  margin: 0;
  font-family: "Roboto-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  font-size: 14px !important;
  color: #404041;
  button:focus,
  a:focus,
  select:focus {
    outline: none !important;
    // opacity: 0.8;
  }
  input,
  textarea {
    width: -webkit-fill-available;
    padding: 10px;
    border: 1px solid white;
    border-radius: 3px;
    resize: none;
    font-family: inherit !important;
    font-size: 15px !important;
  }
  select {
    width: -webkit-fill-available;
    padding: 10px;
    appearance: none;
    border: 1px solid white;
    border-radius: 3px;
    font-family: inherit !important;
    font-size: 15px !important;
    background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='gray'/></g></svg>")
      no-repeat;
    cursor: pointer;
    background-position: right 5px top 50%;
    border: none;
    position: relative;
  }
  button {
    padding: 10px;
    border: 1px solid white;
    border-radius: 3px;
    font-family: inherit !important;
    font-size: 15px !important;
  }
  .react-tel-input .country-list {
    color: black !important;
  }
}
