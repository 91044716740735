.hoolva {
    height: 100vh;
    width: 100%;

    .center {
        align-items: center;
        justify-content: center;
    }

    .flex {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .flexCol{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin-bottom: 20px;
        .half{
            width: 50%;
        }
    }  
    .marginTop{
        margin-top: 20px;
    }
    .main {
        height: calc(100vh - 48px);
        overflow-y: auto;
        overflow-x: hidden;
        button{
            padding: 10px;    
            width: 100%;
            font-size: 14px;
            cursor: pointer;
            border-radius: 2px;
            margin-top: 10px;    
        }
        .title{
            padding: 20px 8px;
            margin-bottom: 20px;
            font-size: 14px;
            
            text-align: center;
        }
        .content {
            padding: 20px;
            min-width:600px;
            border: 1px solid #ccc;
            
        }
        .set{
            display: flex;
            float: right;
            button{
                width: 100px;
                margin-left: 4px;
            }
        }
    }
    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        padding-top: 4px;
        cursor: pointer;
        font-size: 15px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default checkbox */
      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      
      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 5;
        left: 0;
        height: 14px;
        width: 14px;
        border: 1px solid #231f20;
      }
      
      /* On mouse-over, add a grey background color */
      .container:hover input ~ .checkmark {
        background-color: #ccc;
      }
      
      /* When the checkbox is checked, add a blue background */
    //   .container input:checked ~ .checkmark {
    //     background-color: #2196F3;
    //   }
      
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the checkmark when checked */
      .container input:checked ~ .checkmark:after {
        display: block;
      }
      
      /* Style the checkmark/indicator */
      .container .checkmark:after {
        left: 4px;
        top: 1px;
        width: 4px;
        height: 8px;
        border: solid #404041;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      @media only screen and (max-height: 624px) {
        .flex{
          align-items: start;
        }
      }
      .textarea{
        width: 96% !important;
      }
}