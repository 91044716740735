
.dark .react-confirm-alert-body {
    padding: 0px 8px;
    border-radius: 3px;
    height: 148px;
    color: var(--darktextColor1);
    background-color: var(--darkbackground);
    border: 1px solid var(--darkmodalBorderColor);
}
.dark .react-confirm-alert-body h1 {
    font-size: 18px;
    margin: 0px -8px;
    font-family: inherit;
    padding: 8px 8px;
    color: var(--darktextColor1);
    font-weight: 200;
    border-bottom: 1px solid #222;
    margin-bottom: 20px;
}
.lite .react-confirm-alert-body {
    padding: 0px 8px;
    border-radius: 3px;
    height: 148px;
    color: var(--litetextColor1);
    background-color: var(--litebackground);
    border: 1px solid var(--litemodalBorderColor);
}
.lite .react-confirm-alert-body h1 {
    font-size: 18px;
    margin: 0px -8px;
    font-family: inherit;
    padding: 8px 8px;
    color: var(--litetextColor1);
    font-weight: 200;
    border-bottom: 1px solid #222;
    margin-bottom: 20px;
}
.react-confirm-alert-button-group {
    text-align: right;
    display: block;
    margin-top: 20px;
    margin-bottom: 16px;
}
.react-confirm-alert-button-group button {
    width: 80px;
    font-size: 12px !important;
    padding: 10px;
    background-color: var(--darkbutton1BGColor) !important;
    border-radius: 3px;
}
.react-confirm-alert-overlay{
    background-color: rgba(0,0,0, 0.5);
    color: rgb(64, 64, 65);

}