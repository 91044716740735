.buttonPos {
    position: fixed;
    z-index: 8888888;
    bottom: 4px;
    width: 100%;
    height: 56px;
    justify-content: center;
    text-align: center;
    transition: 0.5s;
    .unmute {
        width: 70px !important;
        padding: 2px;
        margin-right: 0px !important;
    }

    .hand {
        width: 48px;
    }
    .disabled {
        color: #ee4923 !important;
    }
    .disabled:hover svg {
        color: #ee4923 !important;
    }

    .button {
        width: auto;
        min-width: 40px;
        margin: 0px 5px;
        padding: 8px;
        // background-color: #404041;
        // border-color: #404041;
        color: #a7a9ab;
        border-radius: 2px;
        svg {
            font-size: 22px;
        }
        span {
            position: fixed;
            margin-top: -6px;
            margin-left: -12px;
            background-color: #f7931d;
            padding: 2px 4px;
            color: #404041;
            border-radius: 8px;
            min-width: 10px;
            font-size: 11px;
        }
    }
    .button:hover {
        background-color: #272727;
    }
    .button:hover svg {
        color: #fff;
    }
    .settings {
        float: left;
        margin-left: 40px;
    }
    .exit {
        float: right;
        margin-right: 50px;
    }
    .exit,
    .exit:hover svg,
    .danger,
    .danger:hover svg,
    .screenShare svg {
        color: #ee4923 !important;
    }
    @media only screen and (max-width: 1024px) {
        .exit {
            margin-right: 20px !important;
        }
        .settings {
            margin-left: 20px !important;
        }
        .button {
            min-width: 44px !important;
            width: 44px !important;
            margin: 8.5px 2px;
            padding: 10px 0px !important;
            div {
                display: none;
            }
        }
    }
    @media only screen and (max-width: 800px) {
        .button {
            min-width: 44px !important;
            width: 44px !important;
            margin: 8.5px 2px;
            padding: 10px 0px !important;
            div {
                display: none;
            }
        }
    }
    @media only screen and (max-width: 800px) {
        .chatButtons {
            width: calc(100% - 302px) !important;
            margin-left: 0px;
        }
        .exit {
            margin-right: 8px !important;
        }
        .settings {
            margin-left: 8px !important;
        }
    }
}
.Recording svg {
    color: #ee4923;
}
.Recording:hover svg {
    color: #ee4923 !important;
}
.PosChange {
    height: 46px;
}
.fluid {
    bottom: 0px !important;
    padding: 0px 0px;
    .button {
        padding: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 48px;
        span {
            margin-top: -3px;
            margin-left: -9px;
        }
    }
    .hand {
        width: 60px;
    }
    svg {
        font-size: 22px !important;
    }
}
.SpeakerchatButtons {
    margin-left: 0px !important;
    width: calc(100% - 302px) !important;
}
.SpeakerButtons {
    margin-left: 0px !important;
    width: calc(100% - 0px) !important;
}
.chatButtons {
    width: calc(100% - 314px);
    padding: 0px 6px;
    @media only screen and (max-width: 1024px) {
        .button {
            margin: 8.5px 2px !important;
            padding: 10px 0px !important;
            div {
                display: none;
            }
        }
        .exit {
            margin-right: 0px !important;
        }
        .settings {
            margin-left: 0px !important;
        }
    }
}
@media only screen and (max-width: 580px) {
    .SpeakerchatButtons {
        display: flex;
    }
}
@media only screen and (max-width: 800px) {
    .chatButtons {
        width: calc(100% - 302px) !important;
        padding: 0px 0px;
    }
}
@media only screen and (max-width: 1024px) {
    .buttonPos {
        height: 60px !important;
    }
    .PosChange {
        height: 60px !important;
    }
}
.speechDetection {
    position: absolute;
    margin-top: -76px !important;
    display: inline-block;
    text-align: left;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 1;
    z-index: 99999999999999999;
    transition-duration: 5s;
    justify-content: left;
    span {
        display: inline-block;
        position: relative;
        background: #000;
        padding: 20px 26px;
        opacity: 0.75;
        font-size: 16px;
        svg {
            margin-right: 16px;
            color: #f7931d;
        }
    }
    span:before {
        content: "";
        position: absolute;
        top: 58px;
        left: 25px;
        box-sizing: border-box;
        z-index: 1;
        border: solid 15px transparent;
        border-top-color: #000;
        // border: 1px solid #666;
    }
}
