.notification {
  position: absolute !important;
  top: 45% !important;
  width: 100%;
  z-index: 999999999;
  text-align: center;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
  transition-duration: 5s;
  justify-content: center;
  span{
    display: inline-block;
    background: #000;
    color: white;
    padding: 20px 60px;
    font-size: 16px;
    opacity: 0.9;
    svg{
      margin-right: 16px;
      color: #f7931d;
    }
  }
}
