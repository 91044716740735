.carouselChat{
    width: calc(100% - 300px) !important;
}
.SpeakerFluidView{
    height: calc(100vh - 115px)  !important;
}
.SpeakerChatFluidView{
    height: calc(100vh - 115px)  !important;
}
.SpeakerView{
    height: calc(100vh)  !important;
}
.SpeakerView, .SpeakerFluidView, .SpeakerChatFluidView{
    width: 240px !important;
    overflow: hidden;
    // background-color: #404041;
    .top, .bottom{
        background-color: #606060;
        border: none;
        border-radius: 0px;
        opacity: 0.4;
    }
    .top:hover, .bottom:hover{
        opacity: 0.8;
    }
    .top{
        position: absolute;
    }
    .bottom{
        position: absolute;
        bottom: 0px;
    }
    li{
        margin-top: -15px;
        list-style: none;
        float: none !important;
    }
}
.Fluid{
    height: calc(100vh - 112px);
    top: 55px !important;
    .left, .right{
        margin-top: calc(((100vh - 110px)/2) - 40px) !important;
    }
}
.carousel{
    width: 100%;
    // transition: 1s;
    transform: 4s;
    position: absolute !important;
    top: 0px;
    left: 0px;
    height: 100vh;
    display: flex  !important;
    .left, .right{
        width: 48px;
        border: none;
        margin-top: calc(((100vh)/2) - 40px);
        cursor: pointer;
        background: #404041;
        border-radius: 0px;
        opacity: 0.4;
        position: absolute;
        height: 80px;
        .icon{
            color:white;
        }
    }
    .left:hover, .right:hover{
        opacity: 0.8;
    }
    .right{
        right: 0px;
    }
    .slider{
        width: 100%;
        ul{
            margin: 0; /* To remove default bottom margin */ 
            padding: 12px 0px; /* To remove default left padding */
        }
        li{
            margin-top: -15px;
            list-style: none;
            float: left;
            
        }
    }
    
    
}
@media only screen and (max-width: 800px) {
    .SpeakerChatFluidView{
        display: none !important;
    }
}