.InfoBox {
  position: fixed;
  z-index: 9999;
  top: 56px;
  left: 8px;
  background-color: #404041;
  padding: 8px;
  color: #afa9ab;
  border-radius: 5px;
  min-width: 300px;
  max-width: 460px;
  .head{
    font-size: 20px !important;
    color: white;
  }
  .node{
    font-size: 16px !important;
    color: white;
  }
  .title{
    width: 80px;
  }
  td{
    padding: 8px 4px;
    font-size: 14px;
  }
  .linkSpan{
    color: #00adef;
    cursor: pointer;
    .linkName{
      margin-left: 4px;
    }
  }
  .linkSpan:hover .linkName{
    text-decoration: underline;
  }
}
