.ScreenShare {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .VideoWrapper {
    overflow: hidden;
    margin: 0px;
    vertical-align: bottom;
    display: inline-block;
    border-radius: 2px;
    margin-top: -5px;
    .gold {
      color: #f7931d !important;
      font-size: 30px;
      margin-left: 5px;
    }
    .reaction {
      // background-color: red;
      float: right;
      margin-right: 10px;
      margin-top: -10px;
      svg {
        width: 50px !important;
        height: 50px !important;
      }
    }
    .NameBoard {
      position: absolute !important;
      z-index: 99999;
      padding-left: 10px !important;
      color: #ccc;

      padding: 2px;
      padding-top: 10px !important;
      border: none !important;

      span {
        padding: 4px 16px;
        position: absolute;
        bottom: 26px;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 2px;
        left: 16px;
      }
    }

    .localvideo {
      transform: rotateY(180deg);
      -webkit-transform: rotateY(180deg);
      /* Safari and Chrome */
      -moz-transform: rotateY(180deg);
    }
  }

  .VideoOverlay {
    position: absolute;
    z-index: 555;
    // border-radius: 2px;
    // border-width: 3px;
    // border-style: solid;
    // border-color: #404041;
    /* display: none; */
  }

  .VideoOverlayAvatar {
    position: absolute;
    // background-color: #404041;
    z-index: 0;
    border-radius: 2px;
    border-width: 0px;
    text-align: center;
    border-style: solid;
    .avatar {
      // display: none;
      height: 25%;
      width: auto;
      margin-top: 20%;
      opacity: 0.6;
      color: #808080;
    }
  }
}
.hideVideo {
  display: none !important;
}
