.modalBG {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 50px;
  opacity: 0.6;
  z-index: 9999999999999999999;
}
.PlayButton{
  padding: 4px 8px;
  // border: 1px solid gray;
  border-radius: 6px;
  cursor: pointer;
}
.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  text-align: center;
  align-content: center;
  z-index: 99999999999999999999;
  .padding8 {
    padding: 8px;
  }
  .desabled{
    cursor: not-allowed !important;
    background-color: #a7a9ab !important;
    border: 1px solid #a7a9ab !important;
}
  .content {
    margin: auto;
    border-radius: 4px;
    width: 600px;
    border: 1px solid #404041;
    font-size: 13px;
    overflow: hidden;
    .header {
      width: 100%;
      text-align: center;
      padding: 2px 6px;
      font-size: 14px;
      .close {
        float: right;
        font-size: 16px;
        cursor: pointer;
        margin: 0px;
        margin-right: 16px;
      }
    }
    .body {
      width: 100%;
      display: flex;
      height: 485px;
      .left {
        padding: 0px;
        background-color: #404041;
        width: 220px;
        .list {
          padding: 10px 4px;
          display: flex;
          color: white;
          cursor: pointer;
          span {
            padding-left: 8px;
          }
        }
        .active {
          background-color: #505050;
        }
        .list:hover {
          background-color: #505050;
        }
      }
      .right {
        width: 100%;
        .listitem {
          padding: 8px;
          .item {
            display: flex;
            margin: 10px 4px;
            .title {
              width: 280px;
              padding-top: 4px;
              text-align: left;
            }
            .data {
              text-align: left;
              display: flex;
              width: 120px !important;
              select {
                padding: 4px;
                font-size: 13px !important;
              }
            }
          }
        }
      }
    }
  }
  .profile{
    text-align: center;
    margin-top: 20px;
    .profileImg{
      display: inline-block;
      padding: 14px 16px;
      background-color: #404041;
      border-radius: 40px;
      margin-bottom: 10px;
    }
    svg{
      font-size: 30px;
      color: #fff;
    }
    .profileChange{
      padding: 4px 10px;
      border-radius: 2px;
      margin-left: 6px;

    }
    .item {
      display: flex;
      margin: 10px 4px;
      margin-top: 20px;
      .title {
        width: 100px;
        padding-top: 4px;
        text-align: left;
        padding-left: 10px;

      }
      .data {
        text-align: left;
        width: 160px !important;
        display: flex;
        input {
          padding: 6px;
          font-size: 13px !important;
        }
      }
    }
  }
  .themes{
    padding: 8px;
    img{
      width: 100%;
      margin-bottom: 8px;
    }
    .image{
      height: 280px;
    }
    .themeButton{
      width: 80px;
      border-radius: 3px;
      margin: 4px;
    }
  }
  .about{
    .flex{
      margin-top: 35%;
      img{
        width: 120px;
        margin-bottom: 10px;
      }
    }
  }
  .settings {
    .TopSpace{
      margin-top: 8px;
    }
    margin-top: 8px;
    text-align: left;
    .header {
      font-weight: bold;
      text-align: left;
      padding-left: 10px;;
    }
    video{
      background-color: #404041;
      margin: 6px 0px;
      margin-left: 10px;
      width: 410px;
      border-radius: 3px;
      height: 230.625px;
      transform: rotateY(180deg);
      -webkit-transform: rotateY(180deg);
      /* Safari and Chrome */
      -moz-transform: rotateY(180deg);
    }
    .item{
      display: flex;
      margin: 2px 0px;
      margin-top: 8px;
      .title {
        width: 90px;
        padding-left: 10px;
        padding-top: 2px;
        text-align: left;
      }
      .data {
        text-align: left;
        width: 320px !important;
        select {
          padding: 4px;
          width: 320px !important;;
          font-size: 13px !important;
        }
      }
    }
    .audioIndicatorWrap {
      display: flex;
      width: 50%;
      margin-left:99px;
      justify-content: space-around;
      align-items: center;
      .audioIndicator {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #a7a9ab;
      }
      .on {
        background-color: #f7931d;
      }
    }
    .speakerBtn {
      font-size: small !important;
      width: 30%;
      padding: 4px;
      margin-left: 99px;
    }
  }


  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    padding-bottom: 20px;
    padding-top: 4px;

    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;

    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #231f20;
    border-radius: 3px;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    border: 1px solid #231f20;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: white;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #404041;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .ProfilePicAvatar{
    height: 80px;
    width: 80px;
    margin: -1px;
    overflow: hidden;
  }
  .uploadButton{
    padding: 32px 50px;
    cursor: pointer;
    text-align: center;
    position: relative;
    color: rgb(204, 204, 204);
    &:hover{
      color: rgb(180, 180, 180);
    }
  }
  .ProfilePic{
    height: 80px;
  }
  .ProfilePicDiv{
    display: flex;
    background-color: #f6f6f6;
    border: 1px solid #ddd;
    width: 390px;
    margin-left: 10px;
    height: 78px;
  }
  .hide{
    opacity: 0.0; position: absolute; top:0; left: 0; bottom: 0; right:0; width: 100%; height:100%;
    cursor: pointer;
  }
  .CropperArea{
    // border: 2px solid red;
    padding: 0px 14px;
    
    // display: flex;
  }
  .UploadButtonSet{
    text-align: left;
    margin-top: 6px;
    margin-left: -6px;
  }
  .width100{
    width: 80px;
  }
  .Preview{
    width: 100px;
    height: 100px;
    display: none;
    // background-color: red;
    img{
      width: 100px;
      height: 100px;
    }
  }
  .displayChange{
    display: flex;
  }
}
