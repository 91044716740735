.VideoWrapper {
    overflow: hidden;
    margin: 2px;
    vertical-align: bottom;
    display: inline-block;
    border-radius: 0px;
    outline: 3px solid;
    outline-offset: -3px;
    .gold {
        color: #f7931d !important;
        font-size: 30px;
        margin-left: 5px;
    }
    .reaction {
        // background-color: red;
        float: right;
        margin-right: 10px;
        margin-top: -10px;
        svg {
            width: 50px !important;
            height: 50px !important;
        }
    }
    .DisplayNameElli {
        white-space: nowrap;
        overflow: hidden;
        padding-right: 2px;
        display: inline-block;
        text-overflow: ellipsis;
    }
    .OpacityVideo {
        opacity: 0;
    }
    .avatarSpin {
        font-size: 32px;
        color: #808080;
    }
    .videoTile {
        position: relative;
        // z-index: 3;
    }
    .TileLoader {
        position: absolute !important;
        height: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 100%;
        z-index: 2;
        display: flex;
    }
    .TileBG {
        position: absolute !important;
        height: 100%;
        width: 100%;
        z-index: 0;
    }
    .flex{
        display: flex;
        margin-left: 0px;
        
        padding-left: 0px !important;
    }
    .NameBoard {
        position: absolute !important;
        z-index: 6;
        padding: 5px 8px;
        border: none !important;
        text-align: left;
        margin-top: 0px;
        height: 100%;
        svg {
            position: absolute;
        }
        .chatMsg {
            height: 206px;
            width: 300px;
            margin-bottom: 6px;
            overflow: hidden;
            position: relative;
            .ChatPos {
                position: absolute;
                bottom: 0px;
                // background-color: green;
                width: 100%;
                .chat {
                    background-color: rgba(0, 0, 0, 0.8);
                    display: table;
                    color: white;
                    margin: 4px 0px;
                    padding: 3px 8px;
                    border-radius: 2px;
                }
            }
        }
        .name {
            padding: 4px 8px;
            padding-right: 22px;
            background-color: rgba(0, 0, 0, 0.8);
            border-radius: 2px;
            display: inline-block;
            display: flex;
            position: absolute;
            bottom: 18px;
            color: #aaa;
            span {
                padding-left: 22px;
            }
        }
    }
    .localvideo {
        transform: rotateY(180deg);
        -webkit-transform: rotateY(180deg);
        /* Safari and Chrome */
        -moz-transform: rotateY(180deg);
    }
}

.VideoOverlay {
    position: absolute;
    z-index: 555;
    border-radius: 0px;
    border-width: 0px;
    border-style: solid;
    /* display: none; */
}
.hideVideo {
    display: none;
}
.activeSpeaker {
    // outline: 3px solid green;
    // outline-offset: -3px;
    border: 3px solid #f7931d;
}
.VideoOverlayAvatar {
    position: absolute;
    z-index: 0;
    border-radius: 0px;
    display: flex;
    // border-width: 0px;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-style: solid;

    .avatar {
        height: 25%;
        width: auto;
        opacity: 0.6;
        color: #808080;
    }
}
