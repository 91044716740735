.hoolva {
    height: 100vh;
    width: 100%;
  
    .center {
      align-items: center;
      justify-content: center;
    }
  
    .flex {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .main {
      height: calc(100vh - 48px);
      .cancelButtonDiv {
        padding: 10px;
        text-align: center;
        margin-bottom: 10px;
        button {
          width: 80px;
          border-radius: 3px;
        }
      }
      .content {
        padding: 20px 20px;
        width: max-content;
        font-size: 20px;
  
        text-align: center;
        display: flex;
        .icon {
          width: 52px;
          margin-top: 10px;
        }
        .connect {
          width: fit-content;
          padding: 4px;
          text-align: left;
          margin-top: 10px;
        }
  
        svg {
          margin-right: 16px;
          font-size: 28px;
          animation-name: backgroundColorPalette;
          animation-duration: 5s;
          animation-iteration-count: infinite;
          animation-direction: alternate;
        }
        @keyframes backgroundColorPalette {
          0% {
            color: red;
          }
          30% {
            color: green;
          }
          60% {
            color: blue;
          }
          100% {
            color: red;
          }
        }
      }
    }
  }
  