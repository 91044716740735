.rightPanels{
    width: 300px;
    height: 100vh;
    // background: #231f20;
    z-index: 888889;
    margin-left: -300px;
    display: flex;
    // border-left: 2px solid #231f20;
    flex-direction: column;
    position: absolute;
    right: 0px;
    top: 0px;
    .tabs{
      display: flex;
      .tab{
        // background-color: #404041;
        padding: 16px;
        color: #a7a9ab;
        width: 50%;
        font-size: 16px;
        text-align: left;
        cursor: pointer;
        padding-bottom: 20px;
        svg{
          float: right;
          visibility: hidden;
          color: #a7a9ab;
        }
      }
      .tab:hover{
        color: #fff;
        svg{
          color: #a7a9ab;
        }
        svg:hover{
          color: #404041 !important;
        }
      }
      .active{
        
        svg{
          visibility: visible;
        }
      }
      .PeopleOnly{
        svg{
          visibility: visible;
        }
      }
    }
  }