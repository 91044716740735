.modalBG{
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 50px;
  opacity: 0.6;
  z-index: 9999999999999999999;
}
.aleft{
  text-align: left;
    width: 144px;
    display: block;
    padding-top: 11px;
}
.modal{
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  text-align: center;
  align-content: center;
  z-index: 99999999999999999999;
  .padding8{
    padding: 8px;
  }
  .right{
    text-align: right !important;
    padding-right: 0px !important;
  }
  .call{
    padding-top: 0px;
  }
  .call input{
    padding: 7px !important;
    height: 19.5px;
    border: 1px solid #ccc !important;
    border-left: 0px !important;
    border-radius: 0px;
    width: 140px;
  }
  .flex{
    display: flex;
    height: 40px;
    padding: 0px 8px;
    .info{
      width: 50%;
      text-align: left;
      margin-top: 1px;
    }
    
    button{
      padding: 0px 2px !important;
      margin-top: -9px;
      border-radius: 0px !important;
    }
    .dropDown{
      text-align: right;
      padding-right: 20px;
      select{
        padding: 6px;
        width: 160px !important;
      }
    }
  }
  ul{
    position: fixed;
    z-index: 666;
  }
  .flexRow{
    display: flex;
    margin-top: 6px;
    margin-bottom: 4px;
    flex-direction: column;
  }
  .content{
    margin: auto;
    border-radius: 4px;
    width: 480px;
    font-size: 14px;
    overflow: hidden;
    .header{
      width: 100%;
      text-align: left;
      padding: 6px 8px;
      .close{
        float: right;
        font-size: 15px;
        cursor: pointer;
        margin: 4px;
        margin-right: 16px;
      }
    }
    .tabs {
      display: flex;
      flex-direction: row;
     
      .active {
        background-color: white;
        color: #404041;
      }
      .tab {
        padding: 10px 0px;
        flex-grow: 1;
        cursor: pointer;
      }
    }
    .validationFailed{
      border: 1px solid red !important;
    }
    .disable{
      background-color: gray;
      border: 1px solid gray !important;
      cursor: not-allowed;
    }
    .body{
      width: 100%;
      display: flex;
      height: 170px;
      
      .left{
        // padding: 10px;
        div{
          // padding-bottom: 20px;
          // padding-top: 10px;
          text-align: left;
        }
        width: 320px;
        textarea{
          height: 80px;
          resize: none;
          margin: 10px;
        }
        .bottomButtons{
          display: flex;
          padding-bottom: 0px !important;
          justify-content: flex-end;
        }
        .space{
          padding: 0px;
          padding-left: 10px;
          button{
            width: 80px;
            padding: 6px 4px;
            border-radius: 2px;
          }
        }
      }
      
      .right{
        width: 160px;
        margin-top: 0px;
        padding: 10px;
        button{
          padding: 6px 4px;
          border-radius: 2px;
        }
        .spacing{
          margin-top: 10px;
        }
      };
    }
  }
}
