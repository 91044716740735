.modalBG{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    // background-color: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    opacity: 0.6;
    z-index: 9999999999999999999;
  }
  .modal{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    display: flex;
    text-align: center;
    align-content: center;
    z-index: 99999999999999999999;
    .padding8{
      padding: 8px;
    }
    .content{
      margin: auto;
      border-radius: 4px;
      width: 480px;
      overflow: hidden;
      .header{
        width: 100%;
        font-size: 16px;
        text-align: left;
        padding: 4px 8px;
        .close{
          float: right;
          font-size: 16px;
          margin: 4px;
          cursor: pointer;
          margin-right: 16px;
        }
        .close:hover{
            opacity: 0.8;
        }
      }
      .body{
        width: 100%;
        .bodyTitle{
            font-size: 16px;
            text-align: left;
            padding: 8px 8px;
        }
        .tabs{
          display: flex;
          flex-direction: row;
          
          .active{
            // border-bottom: 1px solid #f7931d !important;
            // color: #f7931d;
          }
          .tab{
            padding: 12px 0px;
            flex-grow: 1;
            border-bottom: 1px solid #999;
            .titleWithBorder{
              border-right: 1px solid #999;
            }
            
          }
        }
        .tabBody{
          .bodyContent{
            padding: 16px 8px;
            align-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            min-height: 100px;
            button{
              margin: 16px 0px;
              // background-color: orange;
              color: white;
              min-width: 100px;
              padding: 10px 16px;
            }
            button:hover{
                opacity: 0.8;
            }
          }
        }
      }
    }
  }
  