.modalBG {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 50px;
  opacity: 0.6;
  z-index: 9999999999999999999;
}
.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  text-align: center;
  align-content: center;
  z-index: 99999999999999999999;
  .padding8 {
    padding: 8px;
  }
  .content {
    margin: auto;
    background-color: white;
    color: #404041;
    border-radius: 4px;
    width: 420px;
    overflow: hidden;
    .header {
      width: 100%;
      background-color: #404041;
      border-bottom: 1px solid #303031;
      color: white;
      font-size: 20px;
      text-align: left;
      padding: 0px 8px;
      .close {
        float: right;
        font-size: 16px;
        margin: 4px;
        cursor: pointer;
        margin-right: 16px;
      }
      .close:hover {
        opacity: 0.8;
      }
    }
    .body {
      width: 100%;
      background-color: white;
      color: #404041;
      .bodyTitle {
        padding: 8px;
        text-align: left;
        width: 400px;
        margin-left: 77px;
      }
      .tabBody {
        .bodyContent {
          padding: 16px 8px;
          align-content: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 200px;
          .input {
            margin-left: 10px;
          }
          button {
            margin: 16px 4px;
            border-radius: 4px;
            background-color: #404041;
            color: white;
            min-width: 160px;
            height: 140px;
            padding: 10px 16px;
            svg {
              font-size: 32px;
              margin-bottom: 8px;
            }
          }
          button:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
