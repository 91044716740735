.quickChat{
    position: absolute;
    background-color: #404041;
    height: 98px;
    width: 240px;
    margin-top: -100px;
    margin-left: -132px;
    display: inline-block;
    .smiley{
        display: flex;
        svg{
            padding: 0px 0px;
            cursor: pointer;
            font-size: 18px !important;
            color: gold;
        }
        svg:hover{
            color: goldenrod;
        }
    }

    .Text {
        height: 40px;
        background: #404041;
        display: flex;
        input{
          background-color: #231f20;
          border: none;
          color:#a7a9ab;
          border-radius: 0px;
        }
      }
}
