.QMenu{
    position: fixed;
    background-color: #2b343a;
    height: 146px;
    width: 200px;
    bottom: 64px;
    color: #a7a9ab;
    left: 4px;
    padding: 6px;
    display: inline-block;
    svg{
        width:20px !important;
        height: 16px !important;
        margin-right: 8px;
    }
    div{
        padding: 4px 4px;
        text-align: left;
        cursor: pointer;
    }
    div:hover{
        color: white;
    }
    .danger{
        color: red;
    }
}
