.hoolva {
  height: 100vh;
  width: 100%;

  .center {
    align-items: center;
    justify-content: center;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .rightP10{
    padding-right: 10px;
  }
  .Logo {
    width: 100%;
    padding: 0px;
    display: flex;
    margin-bottom: 40px;
    div{
      width: 100%;
      text-align: right;
  }
    img {
      width: 140px;
    }
    button {
      width: 150px;
    }
  }
  .Login {
    font-size: 18px;
    // position: fixed;
    // bottom: 124px;
    margin-bottom: 40px;
    padding: 10px;
    text-align: center;
    width: 100%;
  }
  .main {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .content {
      padding: 20px 10px;
      min-width: 280px;
      font-size: 14px;
      text-align: center;
      display: flex;
      flex-direction: column;
      .TitleHead {
        // position: absolute;
        // margin-top: 6%;
        .Title {
          position: relative;
          font-size: 36px;
          text-align: left;
          color: #f7931d;
        }
        .SubTitle {
          position: relative;
          font-size: 24px;
          text-align: left;
        }
      }
      .TitleHead1 {
        padding: 0px 10px;
        .Title {
          position: relative;
          font-size: 48px;
          text-align: left;
        }
        .SubTitle {
          position: relative;
          font-size: 40px;
          text-align: left;
        }
      }

      img {
        max-width: 100vw;
        max-height: 40vh;
      }
    }
  }
  .Game{
    font-size: 16px;
    text-align: center;
    margin-top: 6px;
    width: auto;
    font-style: italic;
    .GLink{
      color: #03a9f4;;
      font-style: initial;
      margin-left: 4px;
    }
  }
  a{cursor: pointer;
  text-decoration: underline;}
  @media only screen and (max-height: 700px) {
    .content {
      margin-top: 0px;
    }
   
  }
  @media only screen and (max-height: 600px) {
   
    .flex {
      display: block !important;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }


  @mixin fade-in($waitTime) {
    animation:
        wait #{$waitTime},
        fade-in 1000ms #{$waitTime};
  }

  @keyframes wait {
      0% { opacity: 0; }
      60% { opacity: 0; }
      100% { opacity: 0; }
  }

  @keyframes fade-in {
      0% { opacity: 0; }
      60% { opacity: 0; }
      100% { opacity: 1; }
  }
  .anim{
    @include fade-in('1000ms');
    text-align: -webkit-center;
  }
  @media only screen and (max-width: 800px) {
    .Iframe{
      position: relative;
      display: block;
      width: 100% !important;
      height: 36vw !important;
      margin-top: 18px;
      @include fade-in('1000ms');
      border: navajowhite;
    }
  }
  .Gflex{
    display: flex;
    margin-top: 16px;
    justify-content: center;
    @include fade-in('1000ms');
    svg{
      font-size: 22px;
    }
    span{
      font-size: 16px;
      padding: 2px;
      padding-left: 8px;
    }
  }
  .Iframe{
    position: relative;
    display: block;
    width: 40vw;
    height: 24vw;
    margin-top: 18px;
    @include fade-in('1000ms');
    border: navajowhite;
  }
  .dotPulse {
    position: relative;
    display: inline-block;
    margin-left: 16px;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #f7931d;
    color: #f7931d;
    box-shadow: 9999px 0 0 -5px #f7931d;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: 0.25s;
  }

  .dotPulse::before,
  .dotPulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #f7931d;
    color: #f7931d;
  }

  .dotPulse::before {
    box-shadow: 9984px 0 0 -5px #f7931d;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }

  .dotPulse::after {
    box-shadow: 10014px 0 0 -5px #f7931d;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: 0.5s;
  }

  @keyframes dotPulseBefore {
    0% {
      box-shadow: 9984px 0 0 -5px #f7931d;
    }
    30% {
      box-shadow: 9984px 0 0 2px #f7931d;
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -5px #f7931d;
    }
  }

  @keyframes dotPulse {
    0% {
      box-shadow: 9999px 0 0 -5px #f7931d;
    }
    30% {
      box-shadow: 9999px 0 0 2px #f7931d;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -5px #f7931d;
    }
  }

  @keyframes dotPulseAfter {
    0% {
      box-shadow: 10014px 0 0 -5px #f7931d;
    }
    30% {
      box-shadow: 10014px 0 0 2px #f7931d;
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -5px #f7931d;
    }
  }
}
