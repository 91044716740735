.people {
    padding: 0px;
    height: calc(100vh - 53px);
    .List {
        overflow: auto;
        height: calc(100vh - 112px);
    }
    .ListInvite {
        padding: 9px;
        // background-color: red;
        border-top: 1px solid #404041;
        button {
            width: auto;
            span {
                margin-left: 10px;
            }
        }
    }
    .loading{
        animation: fadein 1s;
        -moz-animation: fadein 1s; /* Firefox */
        -webkit-animation: fadein 1s; /* Safari and Chrome */
        -o-animation: fadein 1s; /* Opera */
    }
    @keyframes fadein {
        from {
            opacity:0;
        }
        to {
            opacity:1;
        }
    }
    @-moz-keyframes fadein { /* Firefox */
        from {
            opacity:0;
        }
        to {
            opacity:1;
        }
    }
    @-webkit-keyframes fadein { /* Safari and Chrome */
        from {
            opacity:0;
        }
        to {
            opacity:1;
        }
    }
    @-o-keyframes fadein { /* Opera */
        from {
            opacity:0;
        }
        to {
            opacity: 1;
        }
    }
    .DisplasNameElli {
        max-width: 116px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }
    .title {
        padding: 8px;
        background-color: #404041;
        color: #a7a9ab;
        font-size: 14px !important;
        margin: 2px 0px;
    }
    .WaitingListScroll{
        max-height: calc(50%);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .ParticipantScroll{
        // border: 1px solid red;
        height: calc(100% - 100px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .ParticipantWithWaitingScroll{
        overflow-y: auto;
        overflow-x: hidden;
    }
    .crown {
        --fa-secondary-color: #f7931d;
    }
    .buttons {
        display: flex;
        div {
            width: 80px;
            padding-right: 2px;
            button {
                border-radius: 0px;
                padding: 0px;
                font-size: 14px !important;
                background-color: #404041;
                color: #a7a9ab;
                border: none;
                &:hover {
                    color: #fff;
                }
            }
        }
    }
    .topMargin {
        margin-top: 20px;
    }
    .waitingList {
        padding: 4px 0px;
        display: flex;
        margin-bottom: 0px;

        .button {
            display: flex;
            width: 50%;
            div {
                width: 50%;
                padding-right: 2px;
                button {
                    padding: 6px;
                    background-color: #404041;
                    border: none;
                    color: #a7a9ab;
                    border-radius: 0px;
                    &:hover {
                        opacity: 0.9;
                    }
                }
            }
        }
    }
    .ActiveList {
        padding: 4px 0px;
        display: flex;
        .blank {
            padding: 6px;
            width: 14px;
        }
        .notAllowed {
            cursor: default !important;
        }
        .button {
            display: flex;
            width: 50%;
            text-align: right;
            justify-content: flex-end;
            svg {
                padding: 6px 3px;
                font-size: 16px;
                cursor: pointer;
                color: #a7a9ab;
                width: 20px;
                &:hover {
                    opacity: 0.7;
                }
            }

            .red {
                color: orangered !important;
            }
            .gold {
                color: #f7931d !important;
            }
        }
        .host {
            position: absolute;
            z-index: 999;
            color: #f7931d;
            width: 13px;
            margin: 8px;
            margin-left: 16px;
        }
    }
    .User {
        padding: 0px 6px;
        width: 80%;
        display: flex;
        .avatar {
            padding: 0px;
            margin-right: 4px;
            border-radius: 24px;
            svg {
                width: 26px;
                height: 26px;
                color: #a7a9ab;
            }
        }
        .name {
            padding-top: 7px;
            display: flex;
        }
    }
    .container {
        display: block;
        position: relative;
        padding-left: 24px;
        padding-bottom: 8px;
        margin: 8px;
        padding-top: 4px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .container input {
        position: absolute;
        opacity: 0;

        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 2;
        left: 0;
        height: 14px;
        width: 14px;
        border: 1px solid #231f20;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
        border: 1px solid #231f20;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
        background-color: white;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
        left: 4px;
        top: 1px;
        width: 4px;
        height: 8px;
        border: solid #404041;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
