@import "theme.module";
body {
  margin: 0;
  font-family: 'Roboto-Regular',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  overflow: hidden;
  .App {
    height: 100vh;
    width: 100%;
  }
  button{
        z-index: 88;
        width: 100%;
        font-size: 14px;
        cursor: pointer;
        border-radius: 6px;
        // margin-top: 10px;    
  }
  pre{
    font-family: inherit;
  }
}


