@import "colorDefinitions";
@import "functions";
:root {
  --darklogo: #fff;
  --darkbackground: #000;
  --darkbutton1BGColor: #f7931d;
  --darkbutton1BorderColor:#f7931d;
  --darkbutton1TextColor: #fff;
  --darkbutton1HoverBGColor: #f7931d;
  --darkbutton2BGColor: #000;
  --darkbutton2BorderColor: #f7931d;
  --darkbutton2TextColor: #fff;
  --darkbutton2HoverTextColor: #f7931d;
  --darkbutton3BGColor: #000;
  --darkbutton3BorderColor: #000;
  --darkbutton3TextColor: #fff;
  --darkbutton3HoverBorderColor: #f7931d;
  --darkcheckboxBorder: #fff;
  --darkcheckboxTicColor: #fff;
  --darkradioBorder: #fff;
  --darkradio_selectedColor: #fff;
  --darktextColor1: #fff;
  --darktextColor2: #fff;
  --darktextColor3: #a7a9ab;
  --darktextColorLink: #00adef;
  --darktextboxBGColor: #000;
  --darktextboxBorderColor: #fff;
  --darktextboxActiveColor: #f7931d;
  --darktextboxTextColor: #fff;
  --darktextboxPlaceholderColor: #58585b;
  --darkchatBGColor: #000;
  --darkvideoRoomPrimaryColor: #000;
  --darkvideoRoomSecondaryColor: #202020;
  --darkvideoRoomTernaryColor: #2b343a;
  --darkiconColor: #a7a9ab;
  --darkiconHoverColor: #fff;
  --darkiconHoverBGColor: #3a454c;
  --darkwarningColor: #ee4923;
  --darkdropdownBorderColor: #404041;
  --darkmodalBorderColor: #2b343a;
}
$dark: (
  danger: color(hoolvaDarkRed),
  success: color(hoolvaLightGreen),
  logo: var(--darklogo),
  background: var(--darkbackground),
  button1BGColor: var(--darkbutton1BGColor),
  button1BorderColor: var(--darkbutton1BorderColor),
  button1TextColor: var(--darkbutton1TextColor),
  button1HoverBGColor: var(--darkbutton1HoverBGColor),
  button2BGColor: var(--darkbutton2BGColor),
  button2BorderColor: var(--darkbutton2BorderColor),
  button2TextColor: var(--darkbutton2TextColor),
  button2HoverTextColor: var(--darkbutton2HoverTextColor),
  button3BGColor: var(--darkbutton3BGColor),
  button3BorderColor: var(--darkbutton3BorderColor),
  button3TextColor: var(--darkbutton3TextColor),
  button3HoverBorderColor: var(--darkbutton3HoverBorderColor),
  checkboxBorder: var(--darkcheckboxBorder),
  checkboxTicColor: var(--darkcheckboxTicColor),
  radioBorder: var(--darkradioBorder),
  radio_selectedColor: var(--darkradio_selectedColor),
  textColor1: var(--darktextColor1),
  textColor2: var(--darktextColor2),
  textColor3: var(--darktextColor3),
  textColorLink: var(--darktextColorLink),
  textboxBGColor: var(--darktextboxBGColor),
  textboxBorderColor: var(--darktextboxBorderColor),
  textboxActiveColor: var(--darktextboxActiveColor),
  textboxTextColor: var(--darktextboxTextColor),
  textboxPlaceholderColor: var(--darktextboxPlaceholderColor),
  chatBGColor: var(--darkchatBGColor),
  videoRoomPrimaryColor: var(--darkvideoRoomPrimaryColor),
  videoRoomSecondaryColor: var(--darkvideoRoomSecondaryColor),
  videoRoomTernaryColor: var(--darkvideoRoomTernaryColor),
  iconColor: var(--darkiconColor),
  iconHoverColor: var(--darkiconHoverColor),
  iconHoverBGColor: var(--darkiconHoverBGColor),
  warningColor: var(--darkwarningColor),
  dropdownBorderColor: var(--darkdropdownBorderColor),
  modalBorderColor: var(--darkmodalBorderColor),
);
