
.Slider{
  width: 100% !important;
  height: 100vh;
  display: flex;
  .flexCenter{
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
.Fluid{
  height: calc(100vh - 112px)  !important;
  top: 50px !important;
}
.center {
    align-items: center;
    justify-content: center;
}