.hoolva {
  height: 100vh;
  width: 100%;
  z-index: 1;
  position: absolute;
  .center {
    align-items: center;
    justify-content: center;
  }
  .flex {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    .slowInternet {
      position: fixed;
      top: 10%;
      width: 100%;
      font-size: 14px;
      color: white;
      margin-top: 5px;
      text-align: center;
      z-index: 1;
      transition: 1s;
    }
    .slowInternet span{
      display: inline-block;
      padding: 0 16px;
      opacity: 0.5;
      background-color: black;
    }
    .slowInternet .hideSlow{
      opacity: 0 !important;
    }

  }
  .hideElement {
    display: none;
  }

  .content {
    width: 100%;
  }
  .SpeakerView {
    width: calc(100vw - 242px);
    margin-left: 242px;
    margin-top: -6px;
  }
  .SpeakerChatView {
    width: calc(100vw - 546px);
    margin-left: 242px;
  }
  .leftSpacing {
    margin-left: 122px !important;
  }
  .speechDetection {
    position: absolute !important;
    top: 45% !important;
    width: 100%;
    text-align: center;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 1;
    transition-duration: 5s;
    justify-content: center;
    span {
      display: inline-block;
      background: #000;
      padding: 20px 60px;
      font-size: 16px;
      opacity: 0.9;
      svg {
        margin-right: 16px;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .SpeakerChatView {
      width: calc(100vw - 300px);
      margin-left: 0px;
    }
  }
}
