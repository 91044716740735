.Chat {
  width: 100%;
  height: 100vh;
  z-index: 88888;
  display: flex;
  max-height: calc(100vh - 55px);
  flex-direction: column;
  .gold {
    color: gold;
  }
  .textBx{
    width: 100%;
  }
  .ImogiList {
    background-color: #fff;
    position: absolute;
    bottom: 8px;
    right: 5px;
    width: 290px;
    height: 400px;
    border-radius: 3px;
    border: 1px solid #ddd;
    svg {
      font-size: 18px;
      margin: 8px;
      float: right;
      cursor: pointer;
    }
  }
  .leftText {
    text-align: left;
  }
  .failed span {
    background-color: #666 !important;
    color: white !important;
  }
  .notAllowed {
    cursor: default !important;
  }
  .Msg {
    height: 100%;
    font-size: 11px;
    color: #a7a9ab;
    overflow: auto;
    position: relative;
    .bottom {
      max-height: calc(100vh - 136px);
      overflow: auto;
      position: absolute;
      bottom: 0px;
      width: 300px;
    }
    .left {
      padding: 8px;
    }
    .right {
      padding: 8px;
      text-align: right;
      svg {
        color: #f7931d !important;
      }
    }
    .msgBox {
      display: flex;
      margin: 4px 0px;
      span {
        font-size: 14px;
        border-radius: 4px;
        // color: white;
        min-width: 60px;
        word-break: break-word;
        max-width: 60%;
        padding: 0px;
        padding-right: 4px;
        line-height: 20px;
      }
    }
  }
  .Text {
    height: 52px;
    display: flex;
    input {
      border: none;
      border-radius: 0px;
    }
  }
  .selectBar {
    // background: #404041;
    // color:#a7a9ab;
    option {
      background: #404041;
      color: #a7a9ab;
    }
    svg {
      cursor: pointer;
      margin-left: 10px;
      font-size: 16px;
    }
    .chatSettings {
      padding-top: 10px;
      float: right;
      padding-right: 10px;
      .save {
        cursor: pointer;
      }
    }
    select {
      width: 104px !important;
      padding: 8px 6px;
      // color: #a7a9ab;
      margin: 4px 2px 0px 4px;
      background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>")
        no-repeat;
      cursor: pointer;
      background-position: right 5px top 50%;
      border: none;
      position: relative;
    }
  }
}

.avatarSpin {
  font-size: 24px;
}
.overFlowWrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.DropHere {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 8;
  background-color: gray;
  opacity: 0.4;
  color: white;
}
.DropHere2 {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 6;
  background-color: gray;
  opacity: 0.8;
  color: white;
  margin: auto;
  display: flex;
  justify-content: center;
  justify-items: center;
  div {
    display: block;
    height: fit-content;
    margin: auto;
    font-size: 20px;
  }
  span {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    svg {
      display: block;
      font-size: 48px;
    }
  }
}
.hover {
  cursor: pointer;
}
.progress {
  cursor: wait;
}
.fileUpload {
  display: flex;
  width: 100%;
  background-color: #444;
  max-width: 100% !important;
}
.fileIcon {
  width: 36px;
  min-width: 36px !important;
  svg {
    font-size: 24px;
    padding: 8px;
  }
}
.fileName {
  width: 100%;
  max-width: 100% !important;
}

:global(.emoji-mart-preview) {
  display: none;
}
.disabled {
  cursor: not-allowed !important;
  opacity: 0.4;
  &:hover {
    opacity: 0.4 !important;
  }
}
.sending {
  color:#ccc !important;
  opacity: 1;
}

