.modalBG {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 50px;
  opacity: 0.6;
  z-index: 9999999999999999999;
}
.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  text-align: center;
  align-content: center;
  z-index: 99999999999999999999;
  .close {
    cursor: pointer;
  }
  .padding8 {
    padding: 8px;
  }
  .flex{
    display: flex;
    padding: 10px;
    .info{
      width: 50%;
      text-align: left;
    }
    .dropDown{
      text-align: right;
      padding-right: 20px;
      select{
        padding: 6px;
        width: 160px !important;
      }
    }
  }
  .connectivity button {
    margin: 0px 0px;
    margin-bottom: 16px;
    // background-color: orange;
    color: white;
    padding: 10px 16px;
    width: 160px;
  }
  .getACall {
    margin: 0px 0px;
    margin-bottom: 16px;
    // background-color: orange;
    color: white;
    padding: 10px 16px;
    margin-top: 40px;
    width: 200px;

  }
  .disable{
    background-color: gray;
    cursor: not-allowed;
  }
  .call input{
    padding: 7px !important;
    height: 19.2px;
    border: 1px solid #ccc !important;
    border-left: 0px !important;
    border-radius: 0px;
    width: 140px;
  }
  .right{
    text-align: right !important;
  }
  .content {
    margin: auto;
    border-radius: 4px;
    width: 420px;
    overflow: hidden;
    .header {
      width: 100%;
      font-size: 15px;
      text-align: left;
      padding: 0px 8px;
      .close {
        float: right;
        font-size: 14px;
        margin: 4px;
        margin-right: 16px;
      }
    }
    .body {
      width: 100%;
      height: 220px;
      .tabs {
        display: flex;
        flex-direction: row;

        .active {
          background-color: white;
          color: #404041;
        }
        .tab {
          padding: 10px 0px;
          flex-grow: 1;
          cursor: pointer;
        }
      }
      .tabBody {
        min-height: 140px;
        .bodyContent {
          padding: 16px 8px;
          align-content: center;
          display: flex;
          flex-direction: column;
          align-items: center;

          button {
            margin: 16px 0px;
            // background-color: orange;
            color: white;
            padding: 10px 16px;
          }
        }
      }
    }
  }

}
