.hoolva {
    height: 100vh;
    width: 100%;

    .center {
        align-items: center;
        justify-content: center;
    }

    .flex {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    

    .main {
        height: calc(100vh - 48px);

        .title{
            color: #404041;
            padding: 20px 8px;
            margin-bottom: 20px;
            font-size: 18px;
            text-align: center;
        }
        .content {
            padding: 8px;
            min-width: 360px;

            .logo {
                @extend .flex;

                img {
                    height: 48px;
                }
            }

            
        }
    }
    

}