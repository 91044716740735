.modalBG{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    opacity: 0.6;
    z-index: 99999999999999999999999;
  }
  .modal{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    display: flex;
    text-align: center;
    align-content: center;
    z-index: 99999999999999999999;
    .padding8{
      padding: 8px;
    }
    .content{
      margin: auto;
      border-radius: 4px;
      width: 520px;
      overflow: hidden;
      .header{
        width: 100%;
        border-bottom: 1px solid #303031;
        font-size: 20px;
        text-align: left;
        padding: 0px 8px;
        .close{
          float: right;
          font-size: 16px;
          cursor: pointer;
          margin: 4px;
          margin-right: 16px;
        }
      }
      .body{
        width: 100%;
        display: flex;
        height: 174px;
        .left{
          padding: 10px;
          div{
            padding-bottom: 20px;
            padding-top: 10px;
            text-align: left;
          }
          width: 100%;
          .msg{
            height: 80px;
          }
          .bottomButtons{
            display: flex;
            padding-bottom: 0px !important;
            justify-content: flex-end;
          }
          .space{
            padding: 0px;
            padding-left: 10px;
            button{
              min-width: 80px;
              padding: 8px 10px;
              border-radius: 2px;
            }
          }
        }
        .right{
          width: 160px;
          margin-top: 40px;
          padding: 10px;
          .spacing{
            margin-top: 10px;
          }
        };
      }
    }
    .container {
      display: block;
      position: relative;
      padding-left: 24px;
      padding-bottom: 20px;
      margin: 12px 0px;
      padding-top: 20px;
      cursor: pointer;
      font-size: 14px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    
    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
  
      cursor: pointer;
      height: 0;
      width: 0;
    }
    
    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 5;
      left: 0;
      height: 14px;
      width: 14px;
      border: 1px solid #231f20;
    }
    
    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      border: 1px solid #231f20;
    }
    
    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: white;
    }
    
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }
    
    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: 4px;
      top: 1px;
      width: 4px;
      height: 8px;
      border: solid #404041;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  