.hoolva {
  height: 100vh;
  width: 100%;

  .center {
    align-items: center;
    justify-content: center;
  }

  .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .main {
    height: calc(100vh);
    .content {
      padding: 20px 60px;
      min-width: 280px;
      font-size: 20px;
      margin: 10px;
      text-align: center;
      display: flex;
      align-items:center;
      flex-direction: column;
      .heading {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        padding: 10px;
      }
      .subtitle {
        padding: 10px;
        font-size: 16px;
        text-align: center;
        padding-bottom: 20px;
      }
      button{
        border-radius: 2px;
        padding: 10px;
      }
      .connect {
        padding: 10px ;
        width: 200px;
      }
      .Info {
        padding:10px 10px ;
        padding-top: 20px;
        font-size: 14px;
        width: fit-content;
        display: flex;
        .img{
          width: 32px;
          display: flex;
          height: 32px;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          
        }
        .right{
          // width: 100%;
          text-align: left;
          padding: 0px 10px;
        }
        .Name{
          font-weight: bold;
        }
      }
      .terms {
        padding:20px 10px;
        font-size: 14px;
      }
      .Createbtn {
        padding:10px 10px;
      }
      
    }
  }
  .aToButton{
    color: #00adef;
  }
  .legal{
    margin-top: 50px;
    // color: #404041;
    font-size: 14px;
    text-align: center;
  }
}
